<template>
  <div class="scan-container">
    <div class="top d-f a-c">
      <div class="img-con">
        <img src="@/assets/image/login_logo.png" alt="" />
      </div>
    </div>
    <div class="space">
      <div class="login-box d-f f-d a-c">
        <h3>扫码登录</h3>
        <div class="qrcode-container-total d-f a-c j-c">
          <div class="qrcode-container" ref="qrCodeContainer"></div>
          <div class="qrcode-phone">
            <img src="@/assets/image/login_scan_hover.png" alt="" />
          </div>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="needUpdate">
          <h4>二维码已失效</h4>
          <a-button type="primary" @click="update"> 点击刷新 </a-button>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="hasScan">
          <h4>已扫描</h4>
          <h4>请在手机上确认登录</h4>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="hasSuccess">
          <h4>登录成功，跳转中…</h4>
        </div>
        <p>请使用 微信扫一扫 扫描二维码登录</p>
        <a @click="goLogin" class="login-back">返回登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      uuid: "",
      timer: null,
      baseUrl: process.env.VUE_APP_BASEURL,
      needUpdate: false,
      hasScan: false,
      hasSuccess: false,
    };
  },
  methods: {
    //返回登录
    goLogin() {
      this.$router.push("/login");
    },
    //点击刷新
    update() {
      this.$refs.qrCodeContainer.innerHTML = "";
      this.needUpdate = false;
      this.getUuid();
      this.timer = setInterval(() => {
        this.checkUuid();
      }, 1000);
    },
    //生成二维码
    bindQRCode() {
      new QRCode(this.$refs.qrCodeContainer, {
        text: this.baseUrl + "acl/wx/wxScan/?uuid=" + this.uuid,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //得到uuid
    getUuid() {
      this.$api.getUUID().then((res) => {
        if (res.success) {
          this.uuid = res.data.uuid;
          this.bindQRCode();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //检查uuid状态
    checkUuid() {
      this.$api.checkUUID({ UUID: this.uuid }).then((res) => {
        console.log(res.data.ret);
        if (res.data.ret === "3") {
          clearInterval(this.timer);
          this.hasSuccess = true;
          this.$ls.set("token", res.data.token, 60 * 60 * 1000 * 24);
          this.$api.getUserInfo().then((result) => {
            if (result.success) {
              this.$ls.set("userInfo", result.data, 60 * 60 * 1000 * 24);
              //路由跳转
              this.$message.success("登录成功");
              this.$router.push({ name: "main" });
            }
          });
        } else if (res.data.ret === "2") {
          clearInterval(this.timer);
          this.needUpdate = true;
        } else if (res.data.ret === "1") {
          clearInterval(this.timer);
          this.hasScan = true;
        }
      });
    },
  },
  mounted() {
    // this.getUuid();
    // this.timer = setInterval(() => {
    //   this.checkUuid();
    // }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.scan-container {
  width: 1440px;
  height: 100vh;
  background: url("~@/assets/image/login_bac.png") no-repeat center/100% 100%;
  overflow: hidden;
  .top {
    width: 1440px;
    height: 72px;
    padding-left: 120px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.6);
    .img-con {
      width: 177px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .space {
    width: 1440px;
    height: calc(~"100% - 72px");
    position: relative;
    .login-box {
      width: 430px;
      height: 500px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(52, 38, 0, 0.1);
      opacity: 1;
      border-radius: 16px;
      position: absolute;
      right: 230px;
      top: 72px;
      padding: 60px 50px 0;
      box-sizing: border-box;
      h3 {
        font-weight: bold;
        color: #4f7e46;
        font-size: 24px;
      }
      .dust {
        width: 160px;
        height: 160px;
        position: absolute;
        top: 130px;
        background-color: rgba(255, 255, 255, 0.93);
      }
      .qrcode-container-total {
        width: 140px;
        height: 140px;
        position: absolute;
        top: 140px;
        .qrcode-container {
          position: absolute;
          width: 140px;
          height: 140px;
          left: 0px;
          transition: left 1s;
          ::v-deep canvas {
            width: 100% !important;
            height: 100% !important;
          }
          ::v-deep img {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .qrcode-phone {
          width: 167px;
          height: 177px;
          position: absolute;
          left: 80px;
          display: none;
          transition: display 1s;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .qrcode-container-total:hover .qrcode-container {
        left: -80px;
      }
      .qrcode-container-total:hover .qrcode-phone {
        display: block;
      }
      p {
        position: absolute;
        bottom: 150px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
      .login-back {
        position: absolute;
        bottom: 120px;
      }
    }
  }
}
</style>
